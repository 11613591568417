<template>
  <div class="teacher-show">
    <div class="title-wrap">
      <div class="eng">TEACHERS</div>
      <div class="cn" style="margin-top: -60px">名校长引领 优师资教学</div>
    </div>

    <div class="sub-title">高级职称29.82% 一级职称8.77% 二级职称 38.6%</div>

    <div class="content">
      <div class="left">
        <el-image
          style="width: 298px; height: 298px"
          :src="show1_Src"
          :fit="fit"
          lazy
        ></el-image>
      </div>
      <div class="right">
        <div class="name">贡校长</div>

        <div class="text-wrap">
          <div>
            <span style="color: #000000"
              >——<span
                style="color: #053a7c; font-size: 28px; font-weight: bold"
                >26年</span
              >任职校长经历，<span
                style="color: #053a7c; font-size: 28px; font-weight: bold"
                >41年</span
              >从教经验。</span
            >
            省学测
          </div>
          <div style="margin-top: 12px">
            与高考命题专家库成员、国培计划师资库成员、省
          </div>
          <div style="margin-top: 12px">
            特级教师与正高级教师评审评委库成员。
          </div>
        </div>
      </div>
    </div>

    <div class="img-wrap">
      <el-image
        style="width: 922px; height: 213px"
        :src="show2_Src"
        :fit="fit"
        lazy
      ></el-image>
    </div>
    <div class="img-wrap" style="margin-top: 32px;">
      <el-image
        style="width: 922px; height: 213px"
        :src="show3_Src"
        :fit="fit"
        lazy
      ></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeacherShow',
  data() {
    return {
      show1_Src: require('@/assets/images/teacher_show/show1.png'),
      show2_Src: require('@/assets/images/teacher_show/show2.png'),
      show3_Src: require('@/assets/images/teacher_show/show3.png'),
      fit: 'cover',
    }
  },
}
</script>

<style lang="less" scoped>
.teacher-show {
  margin-bottom: 70px;

  .sub-title {
    font-size: 24px;
    font-weight: 400;
    color: #7c7c7c;
    text-align: center;
    margin-top: 10px;
  }

  .content {
    margin-top: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    .left {
      margin-right: 80px;
    }

    .right {
      .name {
        font-size: 36px;
        font-weight: 500;
        color: #000000;
      }

      .text-wrap {
        margin-top: 40px;
        font-size: 24px;
        font-weight: 500;
        color: #7c7c7c;
      }
    }
  }

  .img-wrap {
    display: flex;
    justify-content: center;
    margin-top: 52px;
  }
}
</style>