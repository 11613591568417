<template>
  <div id="app">
    <!-- 悬浮导航栏 -->
    <div class="hover-box">
      <div class="h-content">
        <div style="margin-right: -10px">
          <el-image
            style="width: 22px; height: 22px"
            :src="logoSrc"
            :fit="fit"
            lazy
          ></el-image>
        </div>
        <div class="title">
          <div class="t-top">嘉兴平湖市毅进卡迪夫公学高级中学</div>
          <div class="t-bottom">JIAXING PINGHU YIJIN CAVC HIGH SCHOOL</div>
        </div>
      </div>

      <!-- 招生简章 -->
      <div class="item">
        <el-image
          style="width: 74px; height: 8px"
          :src="barSrc"
          :fit="fit"
          lazy
        ></el-image>
        <div class="box" @click="toPosition(1)">招生简章</div>
      </div>

      <!-- 预约报名 -->
      <div class="item" style="margin-top: 44px">
        <el-image
          style="width: 74px; height: 8px"
          :src="barSrc"
          :fit="fit"
          lazy
        ></el-image>
        <div class="box" @click="toPosition(2)">预约报名</div>
      </div>

      <!-- 预约观校 -->
      <div class="item" style="margin-top: 44px">
        <el-image
          style="width: 74px; height: 8px"
          :src="barSrc"
          :fit="fit"
          lazy
        ></el-image>
        <div class="box" @click="toPosition(3)">预约观校</div>
      </div>
    </div>
    <TransparentBanner />
    <EducationalCharacteristics />
    <TopForm ref="top_form" />
    <TypeIntroduction />
    <ClassType ref="class_type" />
    <TeacherShow />
    <TeachingCharacteristics />
    <SchoolProfile />
    <SchoolEnvironment />
    <OnlineMessage ref="online_message" />
    <CommonFooter />
  </div>
</template>

<script>
// 引入头部 Banner 组件
import TransparentBanner from './components/TransparentBanner.vue'
// 办学特色 组件
import EducationalCharacteristics from './components/EducationalCharacteristics.vue'
// 上表单 组件
import TopForm from './components/TopForm.vue'
// 班型介绍 组件
import TypeIntroduction from './components/TypeIntroduction.vue'
// 意向班型 组件
import ClassType from './components/ClassType.vue'
// 师资展示 组件
import TeacherShow from './components/TeacherShow.vue'
// 教学特色 组件
import TeachingCharacteristics from './components/TeachingCharacteristics.vue'
// 学校简介 组件
import SchoolProfile from './components/SchoolProfile.vue'
// 学校环境 组件
import SchoolEnvironment from './components/SchoolEnvironment.vue'
// 在线留言 组件
import OnlineMessage from './components/OnlineMessage.vue'
// 页脚 组件
import CommonFooter from './components/CommonFooter.vue'

export default {
  name: 'App',
  components: {
    TransparentBanner,
    EducationalCharacteristics,
    TopForm,
    TypeIntroduction,
    ClassType,
    TeacherShow,
    TeachingCharacteristics,
    SchoolProfile,
    SchoolEnvironment,
    OnlineMessage,
    CommonFooter,
  },
  data() {
    return {
      logoSrc: require('@/assets/images/educational_characteristics/logo.png'),
      barSrc: require('@/assets/images/educational_characteristics/bar.png'),
      fit: 'cover',
    }
  },
  created() {
    (function () {
      var sUserAgent = navigator.userAgent.toLowerCase()
      var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad'
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
      var bIsMidp = sUserAgent.match(/midp/i) == 'midp'
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv: 1.2 .3 .4'
      var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
      var bIsAndroid = sUserAgent.match(/android/i) == 'android'
      var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
      var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        //跳转到移动端首页
        window.location.href = '/m/index.html#'
      }
    })();
  },
  methods: {
    // 跳转到页面上的指定位置
    toPosition(index) {
      switch (index) {
        case 1:
          this.$refs.class_type.$el.scrollIntoView()
          break
        case 2:
          this.$refs.online_message.$el.scrollIntoView()
          break
        case 3:
          this.$refs.top_form.$el.scrollIntoView()
          break
      }
    },
  },
}
</script>

<style lang="less">
// 公共标题样式
.title-wrap {
  text-align: center;
  .eng {
    font-size: 65px;
    font-weight: 800;
    color: rgba(161, 32, 36, 0);
    line-height: 131px;

    background: linear-gradient(
      0deg,
      rgba(213, 213, 213, 0.6) 0%,
      rgba(220, 221, 219, 0.1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .cn {
    font-size: 42px;
    font-weight: 500;
    color: #24304f;
    margin-top: -56px;
    letter-spacing: 2px;
  }
}
</style>

<style lang="less" scoped>
#app {
  min-width: 1400px;
  position: relative;
  .hover-box {
    width: 285px;
    height: 355px;
    background: rgba(28, 41, 87);
    border-radius: 25px;
    position: fixed;
    left: 32.5px;
    top: 502px;
    z-index: 999;

    .h-content {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 38px 0 35px 0;

      .title {
        color: #ffffff;
        text-align: center;
        margin-right: -10px;
        margin-left: 5px;

        .t-top {
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 1px;
          font-family: KaiTi;
          // transform: scale(.6);
        }

        .t-bottom {
          font-size: 12px;
          font-weight: 500;
          transform: scale(0.8);
        }
      }
    }

    .item {
      display: flex;
      align-items: center;

      .box {
        width: 127px;
        height: 37px;
        background: #f0f0f0;
        border-radius: 19px;
        font-size: 22px;
        font-weight: 400;
        color: #1c2957;
        line-height: 37px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
        transition: 1s;

        &:hover {
          background: #5177aa;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
