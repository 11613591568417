<template>
  <div class="school-environment">
    <div class="title-wrap">
      <div class="eng">ENVIRONMENT</div>
      <div class="cn">学校环境</div>
    </div>

    <div class="content">
      <div class="box1">
        <div class="box1-top">
          <el-image
            style="width: 592px; height: 231px"
            :src="s11_Src"
            :fit="fit"
            lazy
          ></el-image>
        </div>
        <div class="box1-bottom">
          <el-image
            style="width: 288px; height: 231px"
            :src="s12_Src"
            :fit="fit"
            lazy
          ></el-image>
          <el-image
            style="width: 288px; height: 231px"
            :src="s13_Src"
            :fit="fit"
            lazy
          ></el-image>
        </div>
      </div>

      <div class="box2">
        <div>
          <el-image
            style="width: 288px; height: 231px"
            :src="s21_Src"
            :fit="fit"
            lazy
          ></el-image>
        </div>
        <div style="margin-top: 13px">
          <el-image
            style="width: 288px; height: 231px"
            :src="s22_Src"
            :fit="fit"
            lazy
          ></el-image>
        </div>
      </div>

      <div class="box3">
        <div class="m-block"></div>
        <el-image
          style="width: 288px; height: 414px"
          :src="s31_Src"
          :fit="fit"
          lazy
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolEnvironment',
  data() {
    return {
      s11_Src: require('@/assets/images/school_environment/s1-1.png'),
      s12_Src: require('@/assets/images/school_environment/s1-2.png'),
      s13_Src: require('@/assets/images/school_environment/s1-3.png'),
      s21_Src: require('@/assets/images/school_environment/s2-1.png'),
      s22_Src: require('@/assets/images/school_environment/s2-2.png'),
      s31_Src: require('@/assets/images/school_environment/s3-1.png'),
      fit: 'cover',
    }
  },
}
</script>

<style lang="less" scoped>
.school-environment {
  margin-bottom: 70px;

  .content {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .box1 {
      margin-right: 15px;

      .box1-bottom {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
      }
    }

    .box2 {
      margin-right: 15px;
    }

    .box3 {
      .m-block {
        width: 288px;
        height: 49px;
        background: #030f42;
        margin-bottom: 15px;
      }
    }
  }
}
</style>