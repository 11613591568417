<template>
  <div class="school-profile">
    <div class="content">
      <div class="vertical-text">SCHOOL PROFILE</div>
      <div class="box">
        <div class="left">
          <div class="l-title">学校简介</div>
          <div class="text-wrap">
            嘉兴平湖市毅进卡迪夫公学高级中学位于长
            三角一体化经济建设区嘉兴平湖,东邻上海市,南濒
            杭州湾,地理位置优越,交通便利。学校占地面积60
            余亩,综合建筑面积超4.8万平方米,绿化占地面积
            逾35%,校园设施优美,是一所综合性特色寄宿制学校,学
            校开设浙江省普通高中(含艺术类) 课程。
          </div>
        </div>
        <div class="right">
          <el-image
            style="width: 770px; height: 494px"
            :src="drawSrc"
            :fit="fit"
            lazy
          ></el-image>
          <div class="mask"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolProfile',
  data() {
    return {
      drawSrc: require('@/assets/images/school_profile/draw.png'),
      fit: 'cover',
    }
  },
}
</script>

<style lang="less" scoped>
.school-profile {
  margin-bottom: 17px;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    .vertical-text {
      writing-mode: vertical-lr;
      font-size: 57px;
      font-weight: 800;
      color: rgba(110, 110, 110, 0.08);
      margin-right: 8px;
    }

    .box {
      width: 1594px;
      height: 494px;
      background-color: rgb(36, 47, 83);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        padding-left: 106px;
        box-sizing: border-box;
        display: flex;
        .l-title {
          writing-mode: vertical-lr;
          font-size: 50px;
          font-weight: 400;
          color: #fffefe;
          margin-top: 6px;
          margin-right: 57px;
        }

        .text-wrap {
          width: 519px;
          // height: 305px;
          font-size: 23px;
          font-weight: 400;
          color: #ffffff;
          line-height: 56px;
          text-indent: 2em;
        }
      }

      .right {
        margin-top: 4px;
        position: relative;

        .mask {
          width: 128px;
          height: 494px;
          background: rgba(158, 48, 58, .6);
          position: absolute;
          right: 133px;
          top: 0;
        }
      }
    }
  }
}
</style>