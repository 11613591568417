<template>
  <div class="online-message">
    <div class="top">在线留言</div>
    <div class="sub-title">
      如果有任何问题请联系我们，我们将以最快的方式联系你！
    </div>

    <div class="content">
      <div class="left">
        <div class="input-wrap">
          <span class="star">*</span>
          <el-input
            v-model="score"
            placeholder="模考/中考分数(必填）"
          ></el-input>
        </div>
        <div class="input-wrap">
          <span class="star">*</span>
          <el-input v-model="name" placeholder="姓名（必填）"></el-input>
        </div>
        <div class="input-wrap">
          <span class="star">*</span>
          <el-input v-model="phone" placeholder="电话（必填）"></el-input>
        </div>
      </div>
      <div class="right">
        <el-input
          type="textarea"
          :rows="9"
          placeholder="详细咨询"
          v-model="content"
        >
        </el-input>
      </div>
    </div>
    <div style="height: 44px"></div>
    <div class="shadow">
      <div class="btn" @click="submit">提交留言</div>
      <div class="bar"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnlineMessage',
  data() {
    return {
      score: '',
      name: '',
      phone: '',
      content: '',
    }
  },
  methods: {
    // js正则：验证手机号
    checkPhone(phone) {
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false
      } else {
        return true
      }
    },
    getQueryString(t) {
      var e = new RegExp('(^|&)' + t + '=([^&]*)(&|$)', 'i'),
        s = new RegExp('(^|/)' + t + '/([^/]*)(/|$)', 'i'),
        a = window.location.search.substr(1).match(e),
        i = window.location.pathname.substr(1).match(s)
      return null != a ? unescape(a[2]) : null != i ? unescape(i[2]) : null
    },
    submit() {
      if (!this.score) {
        this.$message.error('请填写模考/中考分数')
        return
      }
      if (!this.name) {
        this.$message.error('请填写姓名')
        return
      }
      if (!this.phone) {
        this.$message.error('请填写电话')
        return
      }
      if (!this.checkPhone(this.phone)) {
        this.$message.error('请填写正确的电话')
        return
      }
      let postData = {
        data: {
          request_time: parseInt(Date.now() / 1000),
          data_industry: 'ocpc_ps_convert',
          data_detail: {
            qhclickid: this.getQueryString('qhclickid'),
            trans_id: this.$md5(parseInt(Date.now() / 1000).toString()),
            event: 'SUBMIT',
            event_time: parseInt(Date.now() / 1000),
          },
        },
      }
      let config = {
        headers: {
          'App-key': 'cfrees2iih87ogvobanw',
          'App-Sign': this.$md5(
            '0okmv2ypylrxtw0fiwg1s1eyl9upn1vs' + JSON.stringify(postData)
          ),
          'Content-Type': 'application/json;charset=utf-8',
        },
      }
      let t = this.content === '' ? '无' : this.content
      let data = Object.assign(
        { name: this.name, phone: this.phone, content: this.score + ',' + t },
        postData,
        config.headers
      )
      this.$axios({
        url: '/api/default/create-bai-du-pinghu',
        method: 'post',
        data,
      })
        .then(() => {
          this.$message.success('预约成功')
          this.name = ''
          this.phone = ''
          this.content = ''
        })
        .catch((err) => {
          this.$message.error('预约失败', err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.online-message {
  width: 1200px;
  margin: 0 auto 0 auto;
  position: relative;

  .top {
    width: 337px;
    height: 61px;
    background-image: url('@/assets/images/online_message/bgi.png');
    background-size: cover;
    margin: 0 auto 32px auto;
    font-size: 34px;
    font-weight: 500;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
  }

  .sub-title {
    font-size: 23px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }

  .content {
    margin-top: 34px;
    display: flex;
    justify-content: center;
    .left {
      .input-wrap {
        width: 430px;
        height: 57px;
        border: 2px solid #aaaaaa;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding-left: 18px;
        box-sizing: border-box;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        /deep/ .el-input__inner {
          border: none;
        }

        .star {
          font-size: 26px;
          font-weight: 600;
          color: #ff0000;
        }
      }
    }

    .right {
      width: 648px;
      height: 219px;
      border: 2px solid #aaaaaa;
      border-radius: 4px;
      margin-left: 24px;
      padding-top: 5px;
      padding-right: 10px;
      box-sizing: border-box;

      /deep/ .el-textarea__inner {
        border: none;
      }
    }
  }

  .shadow {
    width: 100%;
    height: 73px;
    background: #ffffff;
    box-shadow: 9px 8px 9px 0px rgba(18, 18, 18, 0.57);
    position: absolute;
    left: 0;
    bottom: -53px;

    .btn {
      width: 237px;
      height: 41px;
      background: #5177aa;
      font-size: 22px;
      font-weight: 400;
      color: #ffffff;
      line-height: 41px;
      text-align: center;
      border-radius: 4px;
      margin: 5px auto 0 auto;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background: #3c5a8a;
      }
    }

    .bar {
      width: 100%;
      height: 10px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: -5px;
    }
  }
}
</style>