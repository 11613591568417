import { render, staticRenderFns } from "./TeacherShow.vue?vue&type=template&id=f418d4ac&scoped=true&"
import script from "./TeacherShow.vue?vue&type=script&lang=js&"
export * from "./TeacherShow.vue?vue&type=script&lang=js&"
import style0 from "./TeacherShow.vue?vue&type=style&index=0&id=f418d4ac&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f418d4ac",
  null
  
)

export default component.exports