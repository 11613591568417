<template>
  <div class="common-footer">
    <div class="content">
      <div class="l">
        <div class="l-left">
          <el-image
            style="width: 29px; height: 29px"
            :src="logoSrc"
            :fit="fit"
            lazy
          ></el-image>
        </div>
        <div class="l-right">
          <div class="title">嘉兴平湖市毅进卡迪夫公学高级中学</div>
          <div class="sub-title">JIAXING PINGHU YIJIN CAVC HIGH SCHOOL</div>
        </div>
      </div>
      <div class="m"></div>
      <div class="r">
        <div class="item">
          <el-image
            style="width: 15px; height: 15px"
            :src="schoolSrc"
            :fit="fit"
            lazy
          ></el-image>
          <div class="text">平湖市毅进卡迪夫公学高级中学</div>
        </div>
        <div class="item">
          <el-image
            style="width: 15px; height: 15px"
            :src="addressSrc"
            :fit="fit"
            lazy
          ></el-image>
          <div class="text">浙江省嘉兴市平湖市钟埭街道福善线福臻段188号</div>
        </div>
        <div class="item">
          <el-image
            style="width: 15px; height: 15px"
            :src="phoneSrc"
            :fit="fit"
            lazy
          ></el-image>
          <div class="text">400-600-7883</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonFooter',
  data() {
    return {
      logoSrc: require('@/assets/images/common_footer/logo.png'),
      schoolSrc: require('@/assets/images/common_footer/school.png'),
      addressSrc: require('@/assets/images/common_footer/address.png'),
      phoneSrc: require('@/assets/images/common_footer/phone.png'),
      fit: 'cover',
    }
  },
}
</script>

<style lang="less" scoped>
.common-footer {
  width: 100%;
  height: 204px;
  background: #444444;
  padding-left: 388px;
  box-sizing: border-box;
  overflow: hidden;

  .content {
    margin-top: 91px;
    display: flex;
    align-items: center;
    .l {
      display: flex;
      align-items: center;

      .l-left {
        margin-right: 8px;
      }

      .l-right {
        margin-top: -4px;
        .title {
          font-size: 17px;
          font-family: KaiTi;
          font-weight: 400;
          color: #ffffff;
        }

        .sub-title {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }

    .m {
      width: 1px;
      height: 75px;
      background: #ffffff;
      margin: 0 24px 0 28px;
    }

    .r {
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .text {
          font-size: 12px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          margin-left: 12px;
          margin-top: 1px;
        }
      }
    }
  }
}
</style>