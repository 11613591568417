<template>
  <div class="transparent-banner">
    <div class="header">
      <div class="h-content">
        <el-image
          style="width: 29px; height: 28px"
          :src="logoSrc"
          :fit="fit"
          lazy
        ></el-image>
        <div class="title">
          <div class="t-top">嘉兴平湖市毅进卡迪夫公学高级中学</div>
          <div class="t-bottom">JIAXING PINGHU YIJIN CAVC HIGH SCHOOL</div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="title">
        <div>嘉兴平湖市毅进卡迪夫公学</div>
        <div>高级中学</div>
      </div>

      <div class="bar">
        <div>全日制普高学籍</div>
        <div style="width: 40px"></div>
        <div>文化/艺术双通道</div>
      </div>

      <div class="licenses">
        全日制民办学校办学许可证： 教民233048231000939号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransparentBanner',
  data() {
    return {
      logoSrc: require('@/assets/images/transparent_banner/logo.png'),
      fit: 'cover',
    }
  },
}
</script>

<style lang="less" scoped>
.transparent-banner {
  width: 100%;
  height: 683px;
  background-image: url('@/assets/images/transparent_banner/banner.jpg');
  background-size: cover;
  overflow: hidden;

  .header {
    width: 100%;
    height: 88px;
    background: #09102f;
    opacity: 0.82;
    display: flex;
    align-items: center;
    padding-left: 383px;
    box-sizing: border-box;

    .h-content {
      display: flex;
      align-items: center;

      .title {
        color: #ffffff;
        text-align: center;
        margin-left: 5px;

        .t-top {
          font-size: 17px;
          font-weight: 400;
          letter-spacing: 1px;
          font-family: KaiTi;
        }

        .t-bottom {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }

  .content {
    width: 1202px;
    height: 368px;
    background: rgba(28, 41, 87, 0.75);
    margin: 104px auto 0 auto;
    padding-top: 66px;
    box-sizing: border-box;

    .title {
      font-size: 64px;
      font-weight: 800;
      color: #ffffff;
      line-height: 85px;
      text-align: center;
    }

    .bar {
      margin: 32px auto 28px auto;
      width: 460px;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      border-top: 2px solid rgba(255, 255, 255, 0.5);
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 1px;
    }

    .licenses {
      font-size: 14px;
      font-weight: 300;
      color: #ffffff;
      text-align: end;
      margin-right: 18px;
    }
  }
}
</style>