<template>
  <div class="top-form">
    <div class="content">
      <div class="title">
        <div class="eng">WHY CHOOSE US</div>
        <div class="cn">为什么选择毅进卡迪夫高中</div>

        <div class="line"></div>
      </div>

      <div class="bottom">
        <div class="left">
          <div class="item">
            <el-image
              style="width: 55px; height: 55px"
              :src="icon1_Src"
              :fit="fit"
              lazy
            ></el-image>
            <div class="item-text">体制内高中办学资质</div>
          </div>
          <div class="item">
            <el-image
              style="width: 55px; height: 55px"
              :src="icon2_Src"
              :fit="fit"
              lazy
            ></el-image>
            <div class="item-text">多年集团化办学经验</div>
          </div>
          <div class="item">
            <el-image
              style="width: 55px; height: 55px"
              :src="icon3_Src"
              :fit="fit"
              lazy
            ></el-image>
            <div class="item-text">双导师制规划升学</div>
          </div>
          <div class="item">
            <el-image
              style="width: 55px; height: 55px"
              :src="icon4_Src"
              :fit="fit"
              lazy
            ></el-image>
            <div class="item-text">多路径升学通道</div>
          </div>
        </div>
        <div class="right">
          <div class="input-wrap">
            <el-image
              style="width: 31px; height: 31px"
              :src="userSrc"
              :fit="fit"
              lazy
            ></el-image>
            <el-input v-model="name" placeholder="请填写姓名"></el-input>
          </div>
          <div class="input-wrap" style="margin-top: 26px">
            <el-image
              style="width: 31px; height: 31px"
              :src="phoneSrc"
              :fit="fit"
              lazy
            ></el-image>
            <el-input v-model="phone" placeholder="请填写电话"></el-input>
          </div>
          <div class="btn" @click="submit">点击预约观校</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopForm',
  data() {
    return {
      icon1_Src: require('@/assets/images/top_form/icon1.png'),
      icon2_Src: require('@/assets/images/top_form/icon2.png'),
      icon3_Src: require('@/assets/images/top_form/icon3.png'),
      icon4_Src: require('@/assets/images/top_form/icon4.png'),
      userSrc: require('@/assets/images/top_form/user.png'),
      phoneSrc: require('@/assets/images/top_form/phone.png'),
      fit: 'cover',
      name: '',
      phone: '',
    }
  },
  methods: {
    // js正则：验证手机号
    checkPhone(phone) {
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false
      } else {
        return true
      }
    },
    getQueryString(t) {
      var e = new RegExp('(^|&)' + t + '=([^&]*)(&|$)', 'i'),
        s = new RegExp('(^|/)' + t + '/([^/]*)(/|$)', 'i'),
        a = window.location.search.substr(1).match(e),
        i = window.location.pathname.substr(1).match(s)
      return null != a ? unescape(a[2]) : null != i ? unescape(i[2]) : null
    },
    submit() {
      if (!this.name) {
        this.$message.error('请填写姓名')
        return
      }
      if (!this.phone) {
        this.$message.error('请填写电话')
        return
      }
      if (!this.checkPhone(this.phone)) {
        this.$message.error('请填写正确的电话')
        return
      }
      let postData = {
        data: {
          request_time: parseInt(Date.now() / 1000),
          data_industry: 'ocpc_ps_convert',
          data_detail: {
            qhclickid: this.getQueryString('qhclickid'),
            trans_id: this.$md5(parseInt(Date.now() / 1000).toString()),
            event: 'SUBMIT',
            event_time: parseInt(Date.now() / 1000),
          },
        },
      }
      let config = {
        headers: {
          'App-key': 'cfrees2iih87ogvobanw',
          'App-Sign': this.$md5(
            '0okmv2ypylrxtw0fiwg1s1eyl9upn1vs' + JSON.stringify(postData)
          ),
          'Content-Type': 'application/json;charset=utf-8',
        },
      }
      let data = Object.assign(
        { name: this.name, phone: this.phone },
        postData,
        config.headers
      )
      this.$axios({
        url: '/api/default/create-bai-du-pinghu',
        method: 'post',
        data,
      })
        .then(() => {
            this.$message.success('预约成功')
            this.name = ''
            this.phone = ''
        })
        .catch((err) => {
          this.$message.error('预约失败', err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.top-form {
  width: 100%;
  height: 660px;
  background-image: url('@/assets/images/top_form/bgi.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 1198px;
    height: 527px;
    background: rgba(255, 255, 255, 0.75);
    padding: 30px 64px 0 106px;
    box-sizing: border-box;

    .title {
      position: relative;
      .eng {
        font-size: 66px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        color: rgba(161, 32, 36, 0);

        background: linear-gradient(
          0deg,
          rgba(218, 218, 218, 0.6) 0%,
          rgba(220, 221, 219, 0.1) 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .cn {
        font-size: 42px;
        font-weight: 500;
        color: #24304f;
        margin-top: -40px;
      }

      .line {
        width: 476px;
        height: 4px;
        border-top: 1px solid #1c2957;
        border-bottom: 1px solid #1c2957;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;

      .left {
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 26px;

          &:last-child {
            margin-bottom: 0;
          }

          .item-text {
            font-size: 23px;
            font-weight: 400;
            color: #24304f;
            margin-left: 32px;
          }
        }
      }

      .right {
        .input-wrap {
          width: 534px;
          height: 52px;
          background: #ffffff;
          border-radius: 10px;
          padding-left: 18px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          /deep/ .el-input__inner {
            border: none;
          }
        }

        .btn {
          width: 351px;
          height: 45px;
          background: #bd000f;
          opacity: 0.9;
          border-radius: 23px;
          margin: 47px auto 0 auto;
          font-size: 26px;
          font-weight: 400;
          color: #ffffff;
          line-height: 44px;
          text-align: center;
          cursor: pointer;
          transition: 1s;

          &:hover {
            transform: scale(0.96);
          }
        }
      }
    }
  }
}
</style>