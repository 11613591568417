import { render, staticRenderFns } from "./TypeIntroduction.vue?vue&type=template&id=8d518532&scoped=true&"
import script from "./TypeIntroduction.vue?vue&type=script&lang=js&"
export * from "./TypeIntroduction.vue?vue&type=script&lang=js&"
import style0 from "./TypeIntroduction.vue?vue&type=style&index=0&id=8d518532&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d518532",
  null
  
)

export default component.exports