<template>
  <div class="educational-characteristics">
    <div class="title-wrap">
      <div class="eng">EDUCATIONAL CHARACTERISTICS</div>
      <div class="cn">办学特色</div>
    </div>

    <div class="box-wrap">
      <div class="box">
        <div>
          <el-image
            style="width: 82px; height: 82px"
            :src="icon1_Src"
            :fit="fit"
            lazy
          ></el-image>
          <div class="box-text">标准化普高课程</div>
        </div>
      </div>
      <div class="box">
        <div>
          <el-image
            style="width: 82px; height: 82px"
            :src="icon2_Src"
            :fit="fit"
            lazy
          ></el-image>
          <div class="box-text">精品化教学模式</div>
        </div>
      </div>
      <div class="box">
        <div>
          <el-image
            style="width: 82px; height: 82px"
            :src="icon3_Src"
            :fit="fit"
            lazy
          ></el-image>
          <div class="box-text">衡中化培养机制</div>
        </div>
      </div>
      <div class="box">
        <div>
          <el-image
            style="width: 82px; height: 82px"
            :src="icon4_Src"
            :fit="fit"
            lazy
          ></el-image>
          <div class="box-text">重学术强升学</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'EducationalCharacteristics',
  data() {
    return {
      icon1_Src: require('@/assets/images/educational_characteristics/icon1.png'),
      icon2_Src: require('@/assets/images/educational_characteristics/icon2.png'),
      icon3_Src: require('@/assets/images/educational_characteristics/icon3.png'),
      icon4_Src: require('@/assets/images/educational_characteristics/icon4.png'),
      logoSrc: require('@/assets/images/educational_characteristics/logo.png'),
      barSrc: require('@/assets/images/educational_characteristics/bar.png'),
      fit: 'cover',
    }
  },
}
</script>

<style lang="less" scoped>
.educational-characteristics {
  width: 1202px;
  height: 395px;
  background: #f0f0f0;
  margin: 0 auto;
  position: relative;

  .box-wrap {
    display: flex;
    justify-content: center;
    margin-top: 46px;
    .box {
      width: 206px;
      height: 146px;
      background: #ffffff;
      box-shadow: 0px 10px 20px 0px rgba(28, 41, 87, 0.15);
      border-radius: 12px;
      margin-right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 21px;
      font-weight: 400;
      color: #000000;
      transition: 1s;

      &:hover {
        transform: scale(1.04);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>