<template>
  <div class="teaching-characteristics">
    <div class="title-wrap">
      <div class="eng">EDUCATIONAL CHARACTERISTICS</div>
      <div class="cn">教学特色</div>
    </div>

    <div class="wrap">
      <div class="content">
        <div class="left">
          <el-image
            style="width: 996px; height: 411px"
            :src="bgiSrc"
            :fit="fit"
            lazy
          ></el-image>
          <div class="mask">
            <div class="title">
              <div>平湖卡迪夫</div>
              <div>高级中学</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <el-image
              style="width: 30px; height: 35px"
              :src="messageSrc"
              :fit="fit"
              lazy
            ></el-image>
            <div class="text">分层教学：合理针对差异，提升培优辅基</div>
          </div>
          <div class="item">
            <el-image
              style="width: 30px; height: 35px"
              :src="messageSrc"
              :fit="fit"
              lazy
            ></el-image>
            <div class="text">分类教学：个性化导师制，致力学科平衡 </div>
          </div>
          <div class="item">
            <el-image
              style="width: 30px; height: 35px"
              :src="messageSrc"
              :fit="fit"
              lazy
            ></el-image>
            <div class="text">素质教学：学术德育并行，锻炼综合能力</div>
          </div>
          <div class="item">
            <el-image
              style="width: 30px; height: 35px"
              :src="messageSrc"
              :fit="fit"
              lazy
            ></el-image>
            <div class="text">外教教学：共享外教资源，拓展全球视野</div>
          </div>
        </div>
      </div>
    </div>

    <div class="line-wrap">
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeachingCharacteristics',
  data() {
    return {
      bgiSrc: require('@/assets/images/teaching_characteristics/bgi.png'),
      messageSrc: require('@/assets/images/teaching_characteristics/message.png'),
      fit: 'cover',
    }
  },
}
</script>

<style lang="less" scoped>
.teaching-characteristics {
  margin-bottom: 120px;

  .wrap {
    display: flex;
    justify-content: end;
    .content {
      margin-top: 45px;
      width: 1876px;
      height: 410px;
      background: #9e303a;
      padding-left: 20px;
      padding-right: 250px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .left {
        margin-top: 61px;
        margin-right: 30px;
        position: relative;

        .mask {
          width: 1044px;
          height: 411px;
          background: rgba(158, 48, 58, 0.36);
          position: absolute;
          top: 0;
          left: -48px;

          .title {
            font-size: 50px;
            font-weight: 400;
            color: #ffffff;
            margin: 113px 0 0 572px;
          }
        }
      }

      .right {
        margin-top: 90px;
        .item {
          min-width: 520px;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 36px;

          &:last-child {
            margin-bottom: 0;
          }

          .text {
            margin-left: 52px;
            margin-top: -4px;
          }
        }
      }
    }
  }

  .line-wrap {
    height: 56px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 17px;
    box-sizing: border-box;

    .line {
      width: 827px;
      height: 5px;
      border-top: 6px solid #232C4C;
      border-bottom: 6px solid #9E303A;
    }
  }
}
</style>