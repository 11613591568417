<template>
  <div class="class-type">
    <div class="title-wrap">
      <div class="eng">INTENTIONAL CLASS TYPE</div>
      <div class="cn">意向班型</div>
    </div>

    <div class="radio-wrap">
      <div class="item" v-for="item in typeList" :key="item.id">
        <div
          class="select"
          v-if="item.id !== currentItem"
          @click="changeType(item)"
        ></div>
        <el-image
          v-else
          style="width: 39px; height: 39px"
          :src="selectedSrc"
          :fit="fit"
          lazy
        ></el-image>
        <div class="text">{{ item.name }}</div>
      </div>
    </div>

    <div class="input-wrap">
      <el-image
        style="width: 31px; height: 31px"
        :src="userSrc"
        :fit="fit"
        lazy
      ></el-image>
      <el-input v-model="name" placeholder="请填写姓名"></el-input>
    </div>
    <div class="input-wrap" style="margin-top: 37px">
      <el-image
        style="width: 31px; height: 31px"
        :src="phoneSrc"
        :fit="fit"
        lazy
      ></el-image>
      <el-input v-model="phone" placeholder="请填写电话"></el-input>
    </div>

    <div class="btn" @click="submit">获取招生简章</div>
    <div class="text">* 面向嘉兴市全域内初三应届生</div>
  </div>
</template>

<script>
export default {
  name: 'ClassType',
  data() {
    return {
      typeList: [
        {
          id: 1,
          name: '普高培优班',
        },
        {
          id: 2,
          name: '普高实验班',
        },
        {
          id: 3,
          name: '美术本科定向班',
        },
      ],
      currentItem: 2,
      currentItemName: '普高实验班',
      selectedSrc: require('@/assets/images/class_type/selected.png'),
      userSrc: require('@/assets/images/class_type/user.png'),
      phoneSrc: require('@/assets/images/class_type/phone.png'),
      fit: 'cover',
      name: '',
      phone: '',
    }
  },
  methods: {
    changeType(item) {
      this.currentItem = item.id
      this.currentItemName = item.name
    },
    // js正则：验证手机号
    checkPhone(phone) {
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false
      } else {
        return true
      }
    },
    getQueryString(t) {
      var e = new RegExp('(^|&)' + t + '=([^&]*)(&|$)', 'i'),
        s = new RegExp('(^|/)' + t + '/([^/]*)(/|$)', 'i'),
        a = window.location.search.substr(1).match(e),
        i = window.location.pathname.substr(1).match(s)
      return null != a ? unescape(a[2]) : null != i ? unescape(i[2]) : null
    },
    submit() {
      if (!this.name) {
        this.$message.error('请填写姓名')
        return
      }
      if (!this.phone) {
        this.$message.error('请填写电话')
        return
      }
      if (!this.checkPhone(this.phone)) {
        this.$message.error('请填写正确的电话')
        return
      }
      let postData = {
        data: {
          request_time: parseInt(Date.now() / 1000),
          data_industry: 'ocpc_ps_convert',
          data_detail: {
            qhclickid: this.getQueryString('qhclickid'),
            trans_id: this.$md5(parseInt(Date.now() / 1000).toString()),
            event: 'SUBMIT',
            event_time: parseInt(Date.now() / 1000),
          },
        },
      }
      let config = {
        headers: {
          'App-key': 'cfrees2iih87ogvobanw',
          'App-Sign': this.$md5(
            '0okmv2ypylrxtw0fiwg1s1eyl9upn1vs' + JSON.stringify(postData)
          ),
          'Content-Type': 'application/json;charset=utf-8',
        },
      }
      let data = Object.assign(
        { name: this.name, phone: this.phone, content: this.currentItemName },
        postData,
        config.headers
      )
      this.$axios({
        url: '/api/default/create-bai-du-pinghu',
        method: 'post',
        data,
      })
        .then(() => {
          this.$message.success('预约成功')
          this.currentItem = 2
          this.currentItemName = '普高实验班'
          this.name = ''
          this.phone = ''
        })
        .catch((err) => {
          this.$message.error('预约失败', err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.class-type {
  width: 100%;
  height: 675px;
  background-image: url('@/assets/images/class_type/bgi.jpg');
  background-size: cover;
  padding-top: 33px;
  box-sizing: border-box;
  margin-bottom: 51px;

  .title-wrap {
    .eng {
      line-height: 121px;
      background: linear-gradient(
        0deg,
        rgba(177, 177, 177, 0.6) 0%,
        rgba(177, 177, 177, 0.1) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .cn {
      margin-top: -60px;
    }
  }

  .radio-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    .item {
      display: flex;
      align-items: center;
      margin-right: 94px;

      &:last-child {
        margin-right: 0;
      }
      .select {
        width: 35px;
        height: 35px;
        border: 2px solid #1c2957;
        cursor: pointer;
      }

      .text {
        font-size: 26px;
        font-weight: 500;
        color: #000000;
        margin-left: 26px;
      }
    }
  }

  .input-wrap {
    width: 839px;
    height: 60px;
    background: #ffffff;
    border-radius: 10px;
    margin: 54px auto 0 auto;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;

    /deep/ .el-input__inner {
      border: none;
    }
  }

  .btn {
    width: 523px;
    height: 54px;
    background: #bd000f;
    border-radius: 27px;
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    line-height: 54px;
    text-align: center;
    margin: 52px auto 26px auto;
    cursor: pointer;
    transition: 1s;

    &:hover {
      transform: scale(0.96);
    }
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    color: #444444;
    text-align: center;
  }
}
</style>