<template>
  <div class="type-introduction">
    <div class="title-wrap" style="margin-top: 43px;">
      <div class="eng">TYPE INTRODUCTION</div>
      <div class="cn">班型介绍</div>
    </div>

    <div class="content">
      <!-- 1 -->
      <div class="item">
        <div class="bar"></div>
        <div class="img-wrap">
          <el-image
            style="width: 545px; height: 296px"
            :src="show1_Src"
            :fit="fit"
            lazy
          ></el-image>
        </div>
        <div class="right">
          <div class="down-wrap">
            <el-image
              style="width: 50px; height: 41px"
              :src="downSrc"
              :fit="fit"
              lazy
            ></el-image>
          </div>
          <div class="r-bar">普高毅首班</div>
          <div class="title">名师任课校长督学</div>
          <div class="sub-title">逐梦985/211名校</div>
          <div class="btn-wrap">
            <div class="btn">了解班型 {{ arrow }}</div>
          </div>
        </div>
      </div>

      <!-- 2 -->
      <div class="item" style="margin-top: 95px;">
        <div class="right">
          <div class="down-wrap" style="justify-content: start;">
            <el-image
              style="width: 50px; height: 41px"
              :src="upSrc"
              :fit="fit"
              lazy
            ></el-image>
          </div>
          <div class="r-bar" style="text-align: left; padding-left: 23px;">普高实验班</div>
          <div class="title" style="text-align: left;">分层分类教学</div>
          <div class="sub-title" style="text-align: left;">目标国内各类高等院校大学本科</div>
          <div class="btn-wrap" style="justify-content: start;">
            <div class="btn">了解班型 {{ arrow }}</div>
          </div>
        </div>
        <div class="img-wrap">
          <el-image
            style="width: 545px; height: 296px"
            :src="show2_Src"
            :fit="fit"
            lazy
          ></el-image>
        </div>
        <div class="bar" style="margin-right: 0; margin-left: 16px;"></div>
      </div>

      <!-- 3 -->
      <div class="item" style="margin-top: 95px">
        <div class="bar"></div>
        <div class="img-wrap">
          <el-image
            style="width: 545px; height: 296px"
            :src="show3_Src"
            :fit="fit"
            lazy
          ></el-image>
        </div>
        <div class="right">
          <div class="down-wrap">
            <el-image
              style="width: 50px; height: 41px"
              :src="downSrc"
              :fit="fit"
              lazy
            ></el-image>
          </div>
          <div class="r-bar">美术本科定向班</div>
          <div class="title">杭州黄公望高中联合办学</div>
          <div class="sub-title">对标九大美院/四大艺术学院</div>
          <div class="btn-wrap">
            <div class="btn">了解班型 {{ arrow }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypeIntroduction',
  data() {
    return {
      show1_Src: require('@/assets/images/type_introduction/show1.png'),
      show2_Src: require('@/assets/images/type_introduction/show2.png'),
      show3_Src: require('@/assets/images/type_introduction/show3.png'),
      upSrc: require('@/assets/images/type_introduction/up.png'),
      downSrc: require('@/assets/images/type_introduction/down.png'),
      fit: 'cover',
      arrow: '>>>',
    }
  },
}
</script>

<style lang="less" scoped>
.type-introduction {

  .content {
    margin-top: 44px;
    margin-bottom: 95px;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      .bar {
        width: 16px;
        height: 296px;
        background: #053a7c;
        margin-right: 16px;
      }

      .img-wrap {
        height: 296px;
      }

      .right {
        width: 622px;
        height: 296px;

        .down-wrap {
          height: 59px;
          display: flex;
          justify-content: end;
        }

        .r-bar {
          width: 100%;
          height: 55px;
          background: #1c2957;
          font-size: 32px;
          font-weight: 500;
          color: #ffffff;
          line-height: 52px;
          text-align: end;
          padding-right: 23px;
          box-sizing: border-box;
        }

        .title {
          font-size: 28px;
          font-weight: 500;
          color: #24304f;
          text-align: end;
          margin-top: 20px;
        }

        .sub-title {
          font-size: 24px;
          font-weight: 400;
          color: #7c7c7c;
          text-align: end;
          margin-top: 16px;
        }

        .btn-wrap {
          width: 100%;
          margin-top: 34px;
          display: flex;
          justify-content: end;
          .btn {
            width: 193px;
            height: 41px;
            background: #1c2957;
            border-radius: 0px 0px 0px 30px;
            font-size: 22px;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
            text-align: center;
            padding-left: 2px;
            box-sizing: border-box;
            transition: 0.6s;
            cursor: pointer;

            &:hover {
              background: #053a7c;
            }
          }
        }
      }
    }
  }
}
</style>